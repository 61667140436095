import React from "react";
import { Link, useLocation } from "react-router-dom";
import worldIcon from "../login/world.png";
import homeIcon from "../login/home.png";
import shopIcon from "../login/shop-bag.png";
import documentIcon from "../login/document.png";
import listIcon from "../login/list.png";
import giftIcon from "../login/giftIcon.png";
import voucherIcon from "../login/voucherIcon.png";
import emailIcon from "../login/email.png";
import galleryIcon from "../login/gallery.png";
import surveyIcon from "../login/surveyIcon.png";
import sizeIcon from "../login/sizeIcon.png";
import colorIcon from "../login/colorIcon.png";
import { Helmet } from "react-helmet";

export default function Sidebar() {
  const locate = useLocation();

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_API_SITE_TITLE} Admin</title>
      </Helmet>

      <div id="sidebar" className="collapse collapse-horizontal show ">
        <div
          id="sidebar-nav"
          className="list-group border-0 rounded-0 text-sm-start min-vh-100"
        >
          <Link
            to="/admin/dashboard"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/dashboard" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            {/* <i className="bi bi-house-fill"></i>  */}
            <img src={homeIcon} alt="home-icon" style={{ width: "25px" }} />
            <span> Dashboard</span>{" "}
          </Link>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-document" ? "active" : ""
            }`}
            data-bs-toggle="collapse"
            href="#Document"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/import-file" ? "true" : "false"
            }
            aria-controls="Document"
          >
            {/* <i className="bi bi-filetype-doc"></i> */}
            <img
              src={documentIcon}
              alt="document-icon"
              style={{ width: "25px" }}
            />
            <span> Manage Document</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/import-file" ? "show" : ""
            }`}
            id="Document"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/import-file"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/import-file" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Import File</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#Website"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "true"
                : "false"
            }
            aria-controls="Website"
          >
            {/* <i className="bi bi-globe"></i> */}
            <img src={worldIcon} alt="home-icon" style={{ width: "25px" }} />
            <span> Manage Website</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "show"
                : ""
            }`}
            id="Website"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/manage-website"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-website" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Manage Website</span>{" "}
            </Link>
            <Link
              to="/admin/manage-newsletter"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-newsletter" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Manage Newsletter</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/add-color" ||
              locate.pathname === "/admin/manage-color"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#Colors"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/add-color" ||
              locate.pathname === "/admin/manage-color"
                ? "true"
                : "false"
            }
            aria-controls="Colors"
          >
            {/* <i className="bi bi-gift"></i> */}
            <img src={colorIcon} alt="color-icon" style={{ width: "25px" }} />
            <span> Manage Color</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/add-color" ||
              locate.pathname === "/admin/manage-color"
                ? "show"
                : ""
            }`}
            id="Colors"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/add-color"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/add-color" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Add Color</span>{" "}
            </Link>
            <Link
              to="/admin/manage-color"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-color" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Color</span>{" "}
            </Link>
          </div>
          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/add-size" ||
              locate.pathname === "/admin/manage-size"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#Size"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/add-size" ||
              locate.pathname === "/admin/manage-size"
                ? "true"
                : "false"
            }
            aria-controls="Size"
          >
            {/* <i className="bi bi-gift"></i> */}
            <img src={sizeIcon} alt="size-icon" style={{ width: "25px" }} />
            <span> Manage Sheet Size</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/add-size" ||
              locate.pathname === "/admin/manage-size"
                ? "show"
                : ""
            }`}
            id="Size"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/add-size"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/add-size" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Add Size</span>{" "}
            </Link>
            <Link
              to="/admin/manage-size"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-size" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Sizes</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/orders" ||
              locate.pathname === "/admin/archOrder"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#orders"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/orders" ||
              locate.pathname === "/admin/archOrder"
                ? "true"
                : "false"
            }
            aria-controls="orders"
          >
            {/* <i className="bi bi-cart2"></i> */}
            <img src={shopIcon} alt="shop-icon" style={{ width: "25px" }} />
            <span> Manage Orders</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/orders" ||
              locate.pathname === "/admin/archOrder"
                ? "show"
                : ""
            }`}
            id="orders"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/orders"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/orders" ||
                locate.pathname === "/admin/archOrder"
                  ? "active"
                  : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View All Orders</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/vouchers" ||
              locate.pathname === "/admin/used-vouchers"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#vouchers"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/vouchers" ||
              locate.pathname === "/admin/used-vouchers"
                ? "true"
                : "false"
            }
            aria-controls="vouchers"
          >
            {/* <i className="bi bi-card-heading"></i>  */}
            <img
              src={voucherIcon}
              alt="voucher-icon"
              style={{ width: "25px" }}
            />
            <span> View Vouchers</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/vouchers" ||
              locate.pathname === "/admin/used-vouchers"
                ? "show"
                : ""
            }`}
            id="vouchers"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/vouchers"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/vouchers" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Show Vouchers</span>{" "}
            </Link>
            <Link
              to="/admin/used-vouchers"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/used-vouchers" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Show Used Vouchers</span>{" "}
            </Link>
          </div>
          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-job" ? "active" : ""
            }`}
            data-bs-toggle="collapse"
            href="#job"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/manage-job" ? "true" : "false"
            }
            aria-controls="job"
          >
            {/* <i className="bi bi-list-task"></i> */}
            <img src={listIcon} alt="list-icon" style={{ width: "25px" }} />
            <span> Manage Jobs</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/manage-job" ? "show" : ""
            }`}
            id="job"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/manage-job"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-job" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View All Jobs</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/add-email" ||
              locate.pathname === "/admin/manage-email"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#email"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/add-email" ||
              locate.pathname === "/admin/manage-email"
                ? "true"
                : "false"
            }
            aria-controls="email"
          >
            {/* <i className="bi bi-envelope-at"></i>  */}
            <img src={emailIcon} alt="email-icon" style={{ width: "25px" }} />
            <span> Manage Email</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/add-email" ||
              locate.pathname === "/admin/manage-email"
                ? "show"
                : ""
            }`}
            id="email"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/add-email"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/add-email" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Add Email</span>{" "}
            </Link>
            <Link
              to="/admin/manage-email"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-email" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Email</span>{" "}
            </Link>
          </div>
          {/* <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/add-logo" ||
              locate.pathname === "/admin/manage-logo"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#logo"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/add-logo" ||
              locate.pathname === "/admin/manage-logo"
                ? "true"
                : "false"
            }
            aria-controls="logo"
          >
            <img
              src={galleryIcon}
              alt="gallery-icon"
              style={{ width: "25px" }}
            />
            <span> Manage Logos</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/add-logo" ||
              locate.pathname === "/admin/manage-logo"
                ? "show"
                : ""
            }`}
            id="logo"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/add-logo"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/add-logo" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Upload Logos</span>{" "}
            </Link>
            <Link
              to="/admin/manage-logo"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-logo" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Logos</span>{" "}
            </Link>
          </div> */}

          {/* <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/view-survey" ||
              locate.pathname === "/admin/archive_survey"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#survey"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/view-survey" ||
              locate.pathname === "/admin/archive_survey"
                ? "true"
                : "false"
            }
            aria-controls="survey"
          >
            <img src={surveyIcon} alt="survey-icon" style={{ width: "25px" }} />
            <span> View Survey Info</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/view-survey" ||
              locate.pathname === "/admin/archive_survey"
                ? "show"
                : ""
            }`}
            id="survey"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/view-survey"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/view-survey" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Submitted Survey</span>{" "}
            </Link>
            <Link
              to="/admin/archive_survey"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/archive_survey" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Archive Survey</span>{" "}
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}
