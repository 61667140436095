import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AdminListService from "../../../services/admin-list.service";
import "./login.css";
import Logo from "../../../Images/logo.webp";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux/index";
import { Helmet } from "react-helmet";
import { signImSchema } from "../../../schema";
import ReactLoading from "react-loading";
import $ from "jquery";
import zxcvbn from "zxcvbn";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import ToastLogin from "../includes/ToastLogin";
import { ToastContainer, toast } from "react-toastify";

export default function Login() {
  const showToastMessage = (message, type = "success", options = {}) => {
    const defaultOptions = {
      position:
        type === "success"
          ? toast.POSITION.BOTTOM_CENTER
          : toast.POSITION.BOTTOM_CENTER,
      autoClose: 1500, // 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type === "success") {
      toast.success(message, toastOptions);
    } else if (type === "error") {
      toast.error(message, toastOptions);
    }
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [passwordStrength, setPasswordStrength] = useState(0);

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const [values1, setValues1] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues1({
      ...values1,
      showPassword: !values1.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange1 = (prop) => (event) => {
    setValues1({
      ...values1,
      [prop]: event.target.value,
    });
  };

  const loginCheck = async (values) => {
    setLoading(true);
    try {
      const response = await AdminListService.loginAdmin(values);
      setLoading(false);
      showToastMessage("Login Successfully!", "success");

      await userActions.logIn(
        {
          adminAccessToken: response.data.access_token,
          admin_id: response.data.user.id,
          user_type: response.data.user.user_type,
        },
        "adminLogin"
      );

      setTimeout(() => {
        navigate("/admin/dashboard", { replace: true });
        setLoading(false);
      }, 2500);
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (err?.response?.status === 401) {
        // toast.error("Username or Password is invalid!", {
        //   position: "top-center",
        //   autoClose: 3000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
        showToastMessage(
          "Username or Password is invalid! Please try again.",
          "error"
        );
      } else if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: signImSchema,
      onSubmit: (values, action) => {
        loginCheck(values);
      },
    });

  const handlePasswordChange = (data) => {
    console.log(data.score);
    const result = zxcvbn(data.password);

    // Custom check for minimum 2 lowercase, 2 uppercase, 2 numbers, and 2 special characters
    const lowercaseCount = (data.password.match(/[a-z]/g) || []).length;
    const uppercaseCount = (data.password.match(/[A-Z]/g) || []).length;
    const digitCount = (data.password.match(/[0-9]/g) || []).length;
    const specialCharCount = (
      data.password.match(/[!@#$%^&*(),.?":{}|<>]/g) || []
    ).length;

    const lowerCount = lowercaseCount >= 2 ? 1 : 0;
    const upperCount = uppercaseCount >= 2 ? 1 : 0;
    const digitsCount = digitCount >= 2 ? 1 : 0;
    const specialCount =
      specialCharCount >= 2 ? 2 : specialCharCount == 1 ? 1 : 0;
    const totalCount = lowerCount + upperCount + digitsCount + specialCount;

    // const meetsRequirements =
    //   lowercaseCount >= 2 &&
    //   uppercaseCount >= 2 &&
    //   digitCount >= 2 &&
    //   specialCharCount >= 2;

    // console.log(`score=${data.score}, min=${totalCount}`);
    values.password = data.password;
    setPasswordStrength(totalCount);
  };

  return (
    <div className="login-form">
      <Helmet>
        <title>Admin Panel</title>
      </Helmet>
      <div className="container-login100">
        <div className="wrap-login100">
          <img src={Logo} alt="" className="login-logo" />
          {/* <span className="login100-form-title"> Account Login </span> */}
          <form
            className="login100-form validate-form"
            onSubmit={handleSubmit}
            noValidate
          >
            <div
              className={`wrap-input100 validate-input ${
                errors.username && touched.username ? "alert-validate" : ""
              } `}
              data-validate="Enter Username"
              id="Username"
            >
              <Input
                className={`input100 ${
                  values.username !== "" ? "has-val" : ""
                }`}
                type="text"
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  $("#Username").removeClass("input-focus");
                }}
                onFocus={() => {
                  $("#Username").addClass("input-focus");
                }}
                name="username"
                value={values.username || ""}
                placeholder="Username"
              />
              <span
                className="focus-input100"
                data-placeholder="&#xf007;"
              ></span>
            </div>

            <div
              className={`wrap-input100 validate-input ${
                errors.password && touched.password ? "alert-validate" : ""
              } `}
              data-validate="Enter Password"
              id="password"
            >
              <Input
                className={`input100 ${
                  values.password !== "" ? "has-val" : ""
                }`}
                type={values1.showPassword ? "text" : "password"}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  $("#password").removeClass("input-focus");
                }}
                onFocus={() => {
                  $("#password").addClass("input-focus");
                }}
                name="password"
                value={values.password || ""}
                placeholder="Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values1.showPassword
                        ? values.password.length > 0 && <Visibility />
                        : values.password.length > 0 && <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              {/* <ReactPasswordStrength
                className="customClass"
                minLength={5}
                minScore={2}
                scoreWords={["weak", "okay", "good", "strong", "stronger"]}
                // changeCallback={foo}

                inputProps={{
                  name: "password",
                  autoComplete: "off",
                  className: "form-control",
                }}
              /> */}
              {/* TODO */}
              {/* <PasswordStrength
                style={{ width: "100%" }}
                minLength={6} // Specify your password policy
                minScore={2} // Specify your minimum required score
                changeCallback={handlePasswordChange}
              /> */}
              <span
                className="focus-input100"
                data-placeholder="&#xf023;"
              ></span>
              <Link to="/admin/forgot-pass">Forgot Password</Link>
            </div>

            <div className="container-login100-form-btn">
              <button className="login100-form-btn" type="submit">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#2384d3"
          className="bar-loader"
        />
      )}
    </div>
  );
}
