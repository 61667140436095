import React from "react";
import img from "../../Images/binoculars.png"
const Samp = () => {
  return (
    <div className="row">
      <div className="col-md-1 col-sm-2">
        <img src={img} />
      </div>
      <div className="col-md-11 mt-lg-4" style={{fontFamily: "Arial"}}>
      You will see your voucher number printed on the lower right hand side of the card you have.
      </div>
    </div>
  );
};
export default Samp;
