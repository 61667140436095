import React from "react";
import { useState } from "react";
import "./style.css";
import EmailBox from "./EmailBox";
import Samp from "./Samp";
import ArrowBox from "./ArrowBox";
import Pobox from "./Pobox";
import ContactUs from "./ContactUs";
import Header from "./Header";

import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap.min.css";

import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import UserListService from "../../services/user-list.service";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";

function RegisterForm(props) {
  const navigate = useNavigate();

  const [fields, setFields] = useState({ voucher: "" });
  const [errors, setErrors] = useState({});
  const [i_agree, setI_agree] = useState(false);
  const [captcha, setCaptcha] = useState(true);
  const [showPage, setShowPage] = useState(false);
  const [checkvoucherfield, setCheckvoucherfield] = useState(
    "The voucher number is required."
  );

  useEffect(() => {
    if (localStorage.getItem("returnpage") == "true") {
      navigate("/user-form");
    } else {
      setShowPage(true);
      localStorage.setItem("returnpage", false);

      const keysToKeep = ["@secure.s.acessTokens", "returnpage", "theme"]; // Specify the keys you want to keep
      function clearLocalStorageExcept(keysToKeep) {
        for (let key in localStorage) {
          if (!keysToKeep.includes(key)) {
            localStorage.removeItem(key);
          }
        }
      }

      clearLocalStorageExcept(keysToKeep);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFields((preVal) => ({
      ...preVal,
      [name]: value,
    }));
  };

  const handleChangecheckbox = (e, value) => {
    setI_agree(!i_agree);
  };

  const submituserRegistrationForm = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setFields({ voucher: "" });

      let errors = {};
      const checkCount = 0;

      if (checkCount == 0) {
        if (i_agree) {
          let voucher_two = fields.voucher;

          try {
            const response = await UserListService.registerForm(fields.voucher);

            localStorage.setItem("Survey", response?.data?.response?.survey);
            localStorage.setItem("Logo", response?.data?.response?.logo);

            localStorage.setItem("isVoucher", true);
            const pagename = await response?.data?.response?.redirect;

            if (pagename === "userform") {
              localStorage.setItem("voucher", voucher_two);
              navigate("/user-form");
            }
          } catch (err) {
            if (
              err.response?.status === 404 &&
              err?.response?.data?.response?.redirect === "cert-details"
            ) {
              localStorage.setItem("cert_details", true);
              const detail_data = err?.response?.data?.response;
              secureLocalStorage.setItem(
                "cert_details",
                JSON.stringify({
                  fname: detail_data.fname,
                  lname: detail_data.lname,
                  address: detail_data.address,
                  address2: detail_data.address2,
                  city: detail_data.city,
                  state: detail_data.state,
                  zip: detail_data.zip,
                  phone: detail_data.phone,
                  email: detail_data.email,
                  cardImage: detail_data.prod_image,
                  cardName: detail_data.prod_name,
                  sizeName: detail_data.prod_size,
                })
              );
              navigate("/cert_details");
            } else if (
              err.response?.data?.status === 404 &&
              err?.response?.data?.response?.redirect === "redemption"
            ) {
              errors["voucher"] = err?.response?.data?.response?.message;
              setCheckvoucherfield(err?.response?.data?.response?.message);

              setErrors(errors);
              return false;
            } else {
              errors["voucher"] = err?.response?.data?.response?.message;
              setCheckvoucherfield(err?.response?.data?.response?.message);

              setErrors(errors);
              return false;
            }
          }
        }
      }
    }
  };
  const validateForm = () => {
    // let fields = fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["voucher"]) {
      formIsValid = false;
      errors["voucher"] = checkvoucherfield;

      setErrors(errors);
      return false;
    }

    if (!i_agree) {
      formIsValid = false;
      errors["checkederror"] = "Select the checkbox";

      setErrors(errors);
      return false;
    }
    if (!captcha) {
      formIsValid = false;
      errors["checkederrorcaptcha"] = "captcha not verified";

      setErrors(errors);
      return false;
    }
    return formIsValid;
  };
  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }

  return (
    <>
      {!showPage && <div className="showPage"></div>}
      <Header />

      <div className="container-fluid">
        <div id="main-registration-container">
          <div id="register">
            <div className="white-box">
              <h1>PLEASE READ THIS CAREFULLY</h1>
              <form
                method="post"
                name="userRegistrationForm"
                className="form-outer"
                onSubmit={submituserRegistrationForm}
              >
                <ul className="points">
                  <li>
                    Enter your voucher number below<br></br>
                    <strong>
                      Please Note: Voucher numbers are Case Sensitive with no
                      spaces.
                    </strong>
                  </li>
                  <li>
                    <div className="input-outer1">
                      <input
                        type="text"
                        maxLength="11"
                        placeholder="Enter Voucher Number"
                        name="voucher"
                        className="searchBox_deals vc-validate"
                        value={fields.voucher || ""}
                        onChange={handleChange}
                      />
                      <div className="errorMsg">{errors.voucher}</div>
                    </div>
                  </li>
                  <li key="uniqueId3">
                    <EmailBox />
                  </li>
                  <li key="uniqueId4">
                    <Samp />
                  </li>
                  {/* <li key="uniqueId45">
                    <ArrowBox />
                  </li>
                  <li key="uniqueId453">
                    <Pobox />
                  </li> */}
                  <li key="uniqueId5">
                    <ContactUs />
                  </li>
                  <li className="removing" key="uniqueId6">
                    <label>
                      <input
                        type="checkbox"
                        id="myCheck"
                        name="test"
                        className="conditions"
                        value={fields.checkbox}
                        onChange={handleChangecheckbox}
                      />
                      <span> YES,I HAVE READ THE ABOVE INFORMATION</span>
                      <div className="errorMsg">{errors.checkederror}</div>
                    </label>
                    <br></br>
                    <div className="overflow-hider">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                        onChange={onCaptchaChange}
                      />
                    </div>
                    <div className="errorMsg">{errors.checkederrorcaptcha}</div>
                    <input
                      // onClick={() => myTimeout()}
                      type="submit"
                      name="submit"
                      id="submit"
                      value="SUBMIT"
                      className="sub-btn button"
                    ></input>
                  </li>

                  <br />
                  <li key="uniqueId7" style={{ fontFamily: "Arial" }}>
                    Feel free to contact our customer service team
                    <a href="mailto:customercare@eliterewards.biz">
                      {" "}
                      {process.env.REACT_APP_DESC_CUSTOMERCARE}
                    </a>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default RegisterForm;
