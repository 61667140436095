import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";

function CertdDtails(props) {
  const isVoucher = localStorage.getItem("cert_details");
  const navigate = useNavigate();
  var cert_details = secureLocalStorage.getItem("cert_details");
  var RedemObject = JSON.parse(cert_details);

  useEffect(() => {
    if (isVoucher !== "true" || isVoucher === undefined || isVoucher === null) {
      navigate("/");
    } else {
      localStorage.setItem("cert_details", false);
    }
  }, []);
  return (
    <>
      <div className="container-fluid">
        <Header />
        {/* <div className="header_lower">CITY Furniture and Elite Rewards are proud supporters of the American Heart Association</div> */}
        <div className="white-box">
          {/* <h1>Gift Card Redemption Site</h1>
          <hr /> */}
          <h2>Contact Information</h2>
          <hr />
          <form className="form-outer register clearfix">
            <div className="row">
              <div className="col-md-6">
                <label>
                  <strong>First Name </strong>
                  <br />
                  {RedemObject?.fname ? RedemObject.fname : "N/A"}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>Last Name</strong>
                  <br />
                  {RedemObject?.lname ? RedemObject.lname : "N/A"}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>Address</strong>
                  <br />
                  {RedemObject?.address ? RedemObject.address : "N/A"}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>Address2</strong>
                  <br />
                  {RedemObject?.address2 ? RedemObject.address2 : "N/A"}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>City </strong>
                  <br />
                  {RedemObject?.city ? RedemObject.city : "N/A"}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>State </strong>
                  <br />
                  {RedemObject?.state ? RedemObject.state : "N/A"}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>Zip Code</strong>
                  <br />
                  {RedemObject?.zip ? RedemObject.zip : "N/A"}
                </label>
              </div>{" "}
              <div className="col-md-6">
                <label>
                  <strong>Phone No </strong>
                  <br />
                  {RedemObject?.phone ? RedemObject.phone : "N/A"}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>Email </strong>
                  <br />
                  {RedemObject?.email ? RedemObject.email : "N/A"}
                </label>
              </div>
              <hr />
              <h2>Pick Your Sheets</h2>
              <hr />
              <div className="col-md-12">
                <div className="row">
                  <h1 className="h1-deading">Size</h1>
                  <div className="check-boexs cen">
                    <div className="reward_cert">
                      <span className="insdex">
                        {RedemObject?.sizeName ? RedemObject.sizeName : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="col-md-12">
                <div className="row">
                  <h1 className="h1-deading">Color</h1>
                  <div className="check-boexs cen">
                    <div className="reward_cert">
                      <span className="insdex">
                        {RedemObject?.cardName ? RedemObject.cardName : ""}
                      </span>
                      {RedemObject?.cardImage && (
                        <img
                          src={   
                            process.env.REACT_APP_API_GIFT_IMAGES +
                            RedemObject?.cardImage
                          }
                          className="imgcolor img-responsive"
                          style={{ height: "90px" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/" className="btn btn-md btn-primary">
              Go Back
            </Link>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default CertdDtails;
