import React, { useEffect, useState } from "react";
import Sidebar from "../includes/Sidebar";
import MainHeading from "../includes/MainHeading";
import Footer from "../includes/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ReactLoading from "react-loading";
import { updateVoucher } from "../../../schema";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import ImageLoader from "../includes/ImageLoader";
import $ from "jquery";

export default function EditVoucher() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [formSubmiting, setFormSubmiting] = useState(false);
  const [tableLoader, settableLoader] = useState(false);

  const [tableData, settableData] = useState([]);
  const [sizeData, setSizeData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [errorsServer, setErrorsServer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (adminAccessToken) {
      const getSingleVoucher = async () => {
        setLoading(true);

        try {
          const response = await AdminListService.getVoucherById(id);
          // const res = response?.data?.response?.choices;
          setCardData(response?.data?.response?.choices);
          setIsLoading(false);

          setValues({
            Company: response?.data?.response?.company,
            Certificate: response?.data?.response?.certificate,
            Amount: response?.data?.response?.amount,

            Received:
              response?.data?.response?.received !== "" &&
              response?.data?.response?.received !== " " &&
              response?.data?.response?.received !== "0000-00-00" &&
              moment(response?.data?.response?.received).format("YYYY-MM-DD"),
            First: response?.data?.response?.fname,
            Street: response?.data?.response?.street,
            State: response?.data?.response?.state,

            issuance:
              response?.data?.response?.issuance !== "" &&
              response?.data?.response?.issuance !== " " &&
              response?.data?.response?.issuance !== "0000-00-00" &&
              moment(response?.data?.response?.issuance).format("YYYY-MM-DD"),

            CertiStatus: response?.data?.response?.status,
            Job: response?.data?.response?.job,
            Concatenated: response?.data?.response?.concatenated,
            shipCost: response?.data?.response?.shipCost,
            Shipped:
              response?.data?.response?.shipped !== "" &&
              response?.data?.response?.shipped !== " " &&
              response?.data?.response?.shipped !== "0000-00-00" &&
              moment(response?.data?.response?.shipped).format("YYYY-MM-DD"),
            Fulfilled: response?.data?.response?.fulfilled,
            Last: response?.data?.response?.lname,
            City: response?.data?.response?.city,
            Zip: response?.data?.response?.zip,
            Expiration:
              response?.data?.response?.expiration !== "" &&
              response?.data?.response?.expiration !== " " &&
              response?.data?.response?.expiration !== "0000-00-00" &&
              moment(response?.data?.response?.expiration).format("YYYY-MM-DD"),
            Denomination: response?.data?.response?.denomination,
          });

          setLoading(false);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
          setLoading(false);

          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };
      getSingleVoucher();

      const giftsLists = async () => {
        settableLoader(true);
        try {
          const responce = await AdminListService.getAllGifts();

          const res = responce?.data?.response;

          const results = [];
          res.map((value) => {
            return results.push({
              CardName: value.name,
              CardImage: value.image,
              CardId: value.id,
            });
          });

          settableData([...results]);

          settableLoader(false);
        } catch (err) {
          console.log(err);
          settableData([]);

          settableLoader(false);
        }
      };
      const sizeLists = async () => {
        settableLoader(true);
        try {
          const responce = await AdminListService.getAllLogos();

          const res = responce?.data?.response;

          const results = [];
          res.map((value) => {
            return results.push({
              CardName: value.name,
              CardImage: value.image,
              CardId: value.id,
            });
          });

          setSizeData([...results]);

          settableLoader(false);
        } catch (err) {
          console.log(err);
          setSizeData([]);

          settableLoader(false);
        }
      };
      sizeLists();
      giftsLists();
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const updateVoucherFun = async (values) => {
    var selectedSize = await $(
      ".size-lists .on-off-cards input:checkbox:checked"
    )
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    var selectedColor = await $(
      ".color-lists .on-off-cards input:checkbox:checked"
    )
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedSize.length && !selectedColor.length) {
      toast.error("Please select Size & Color!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!selectedSize.length && selectedColor.length) {
      console.log("Size");
      toast.error("Please select Size!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (selectedSize.length && !selectedColor.length) {
      console.log("Color");
      toast.error("Please select Color!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (selectedSize.length && selectedColor.length) {
      setFormSubmiting(true);
      try {
        const response = await AdminListService.updateVoucherById(
          values,
          admin_id,
          id,
          selectedColor,
          selectedSize
        );

        toast.success(response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setFormSubmiting(false);
        setTimeout(() => {
          navigate("/admin/vouchers", { replace: true });
        }, 1000);
      } catch (err) {
        console.log(err);
        setFormSubmiting(false);

        if (
          err?.response?.data?.response &&
          typeof err?.response?.data?.response === "string"
        ) {
          toast.error(err?.response?.data?.response, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          let errorData = {};

          errorData["Company"] =
            err.response?.data &&
            err.response?.data?.company &&
            err.response?.data?.company[0];
          errorData["Job"] =
            err.response?.data &&
            err.response?.data?.job &&
            err.response?.data?.job[0];
          errorData["Certificate"] =
            err.response?.data &&
            err.response?.data?.certificate &&
            err.response?.data?.certificate[0];
          errorData["Expiration"] =
            err.response?.data &&
            err.response?.data?.expiration &&
            err.response?.data?.expiration[0];
          errorData["Concatenated"] =
            err.response?.data &&
            err.response?.data?.concatenated &&
            err.response?.data?.concatenated[0];
          errorData["Amount"] =
            err.response?.data &&
            err.response?.data?.amount &&
            err.response?.data?.amount[0];
          errorData["shipCost"] =
            err.response?.data &&
            err.response?.data?.shipCost &&
            err.response?.data?.shipCost[0];
          errorData["Shipped"] =
            err.response?.data &&
            err.response?.data?.shipped &&
            err.response?.data?.shipped[0];
          errorData["Received"] =
            err.response?.data &&
            err.response?.data?.received &&
            err.response?.data?.received[0];

          errorData["First"] =
            err.response?.data &&
            err.response?.data?.fname &&
            err.response?.data?.fname[0];
          errorData["Last"] =
            err.response?.data &&
            err.response?.data?.lname &&
            err.response?.data?.lname[0];
          errorData["Street"] =
            err.response?.data &&
            err.response?.data?.street &&
            err.response?.data?.street[0];

          errorData["City"] =
            err.response?.data &&
            err.response?.data?.city &&
            err.response?.data?.city[0];

          errorData["State"] =
            err.response?.data &&
            err.response?.data?.state &&
            err.response?.data?.state[0];

          errorData["issuance"] =
            err.response?.data &&
            err.response?.data?.issuance &&
            err.response?.data?.issuance[0];

          errorData["Fulfilled"] =
            err.response?.data &&
            err.response?.data?.fulfilled &&
            err.response?.data?.fulfilled[0];

          errorData["Zip"] =
            err.response?.data &&
            err.response?.data?.zip &&
            err.response?.data?.zip[0];

          errorData["Denomination"] =
            err.response?.data &&
            err.response?.data?.denomination &&
            err.response?.data?.denomination[0];

          setErrorsServer(errorData);
          $("html, body").animate(
            {
              scrollTop: 0,
            },
            600
          );
          toast.error("Something went wrong, try again", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    setErrors,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Company: "",
      Certificate: "",
      Amount: "",
      Received: "",
      First: "",
      Street: "",
      State: "",
      issuance: "",
      CertiStatus: "",
      Job: "",
      Concatenated: "",
      Shipped: "",
      shipCost: "",
      Fulfilled: "",
      Last: "",
      City: "",
      Zip: "",
      Expiration: "",
      Denomination: "",
    },
    // validationSchema: updateVoucher,
    onSubmit: (values) => {
      updateVoucherFun(values);
    },
  });

  const handleButtonClick = () => {
    setTimeout(() => {
      const firstError = document.querySelector(".error-message");
      if (firstError) {
        // firstError.scrollIntoView({ behavior: "smooth" });
        const rect = firstError.getBoundingClientRect();
        const offsetTop = rect.top - 200 + window.scrollY;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Update Voucher" />

          <form className="mt-4 row" onSubmit={handleSubmit} noValidate>
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Edit Your Voucher</h4>
                </div>
                <div className="card-body">
                  {loading ? (
                    <Skeleton
                      count={20}
                      height="58px"
                      width="48.9%"
                      inline={true}
                      className="m-2"
                    />
                  ) : (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Company{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Company"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Company"
                            value={values.Company || ""}
                          />
                          {errors.Company && touched.Company ? (
                            <span className="text-danger">
                              {errors.Company}
                            </span>
                          ) : errorsServer.Company ? (
                            <span className="text-danger">
                              {errorsServer.Company}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Job{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Job"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Job"
                            value={values.Job || ""}
                          />
                          {errors.Job && touched.Job ? (
                            <span className="text-danger">{errors.Job}</span>
                          ) : errorsServer.Job ? (
                            <span className="text-danger">
                              {errorsServer.Job}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Certificate{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Certificate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Certificate"
                            value={values.Certificate || ""}
                          />
                          {errors.Certificate && touched.Certificate ? (
                            <span className="text-danger">
                              {errors.Certificate}
                            </span>
                          ) : errorsServer.Certificate ? (
                            <span className="text-danger">
                              {errorsServer.Certificate}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Concatenated{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Concatenated"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Concatenated"
                            value={values.Concatenated || ""}
                          />
                          {errors.Concatenated && touched.Concatenated ? (
                            <span className="text-danger">
                              {errors.Concatenated}
                            </span>
                          ) : errorsServer.Concatenated ? (
                            <span className="text-danger">
                              {errorsServer.Concatenated}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Amount"
                            value={values.Amount || ""}
                          />
                          {errors.Amount && touched.Amount ? (
                            <span className="text-danger">
                              The amount must be a number.
                            </span>
                          ) : errorsServer.Amount ? (
                            <span className="text-danger">
                              {errorsServer.Amount}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Ship Cost</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ship Cost"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="shipCost"
                            value={values.shipCost || ""}
                          />
                          {errors.shipCost && touched.shipCost ? (
                            <span className="text-danger">
                              The ship cost must be a number.
                            </span>
                          ) : errorsServer.shipCost ? (
                            <span className="text-danger">
                              {errorsServer.shipCost}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Shipped</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Shipped"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Shipped"
                            value={values.Shipped || ""}
                          />
                          {errors.Shipped && touched.Shipped ? (
                            <span className="text-danger">
                              {errors.Shipped}
                            </span>
                          ) : errorsServer.Shipped ? (
                            <span className="text-danger">
                              {errorsServer.Shipped}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Received</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Received"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Received"
                            value={values.Received || ""}
                          />
                          {errors.Received && touched.Received ? (
                            <span className="text-danger">
                              {errors.Received}
                            </span>
                          ) : errorsServer.Received ? (
                            <span className="text-danger">
                              {errorsServer.Received}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Fulfilled</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Fulfilled"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Fulfilled"
                            value={values.Fulfilled || ""}
                          />
                          {errors.Fulfilled && touched.Fulfilled ? (
                            <span className="text-danger">
                              {errors.Fulfilled}
                            </span>
                          ) : errorsServer.Fulfilled ? (
                            <span className="text-danger">
                              {errorsServer.Fulfilled}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            First Name{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="First"
                            value={values.First || ""}
                          />
                          {errors.First && touched.First ? (
                            <span className="text-danger">{errors.First}</span>
                          ) : errorsServer.First ? (
                            <span className="text-danger">
                              {errorsServer.First}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Last Name{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Last"
                            value={values.Last || ""}
                          />
                          {errors.Last && touched.Last ? (
                            <span className="text-danger">{errors.Last}</span>
                          ) : errorsServer.Last ? (
                            <span className="text-danger">
                              {errorsServer.Last}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Street</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Street"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Street"
                            value={values.Street || ""}
                          />
                          {errors.Street && touched.Street ? (
                            <span className="text-danger">{errors.Street}</span>
                          ) : errorsServer.Street ? (
                            <span className="text-danger">
                              {errorsServer.Street}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            City{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="City"
                            value={values.City || ""}
                          />
                          {errors.City && touched.City ? (
                            <span className="text-danger">{errors.City}</span>
                          ) : errorsServer.City ? (
                            <span className="text-danger">
                              {errorsServer.City}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            State{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="State"
                            value={values.State || ""}
                          />
                          {errors.State && touched.State ? (
                            <span className="text-danger">{errors.State}</span>
                          ) : errorsServer.State ? (
                            <span className="text-danger">
                              {errorsServer.State}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Zip{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Zip"
                            value={values.Zip || ""}
                          />
                          {errors.Zip && touched.Zip ? (
                            <span className="text-danger">{errors.Zip}</span>
                          ) : errorsServer.Zip ? (
                            <span className="text-danger">
                              {errorsServer.Zip}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Begin Redemption</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="issuance"
                            value={values.issuance || ""}
                          />
                          {errors.issuance && touched.issuance ? (
                            <span className="text-danger">
                              {errors.issuance}
                            </span>
                          ) : errorsServer.issuance ? (
                            <span className="text-danger">
                              {errorsServer.issuance}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Expiration</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Expiration"
                            value={values.Expiration || ""}
                          />
                          <span className="text-danger">
                            (MM/DD/YYYY) eg : 12/25/2014
                          </span>
                          {errors.Expiration && touched.Expiration ? (
                            <span className="text-danger">
                              {errors.Expiration}
                            </span>
                          ) : errorsServer.Expiration ? (
                            <span className="text-danger">
                              {errorsServer.Expiration}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box mb-md-0">
                          <label>Certificate Status</label>
                          <input
                            type="text"
                            className={`form-control text-capitalize ${
                              values.CertiStatus === "active" ||
                              values.CertiStatus === "Valid"
                                ? "text-success"
                                : values.CertiStatus === "used"
                                ? "text-primary"
                                : values.CertiStatus === "expired" ||
                                  values.CertiStatus === "archive"
                                ? "text-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="CertiStatus"
                            value={values.CertiStatus || ""}
                            readOnly={true}
                          />
                          {errors.CertiStatus && touched.CertiStatus ? (
                            <span className="text-danger">
                              {errors.CertiStatus}
                            </span>
                          ) : errorsServer.CertiStatus ? (
                            <span className="text-danger">
                              {errorsServer.CertiStatus}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box mb-0">
                          <label>Denomination</label>
                          <input
                            type="TEXT"
                            className="form-control"
                            placeholder="Denomination"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Denomination"
                            value={values.Denomination || ""}
                          />
                          {errors.Denomination && touched.Denomination ? (
                            <span className="text-danger">
                              The denomination must be a number.
                            </span>
                          ) : errorsServer.Denomination ? (
                            <span className="text-danger">
                              {errorsServer.Denomination}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isLoading && (
              <div className="mt-4  col-md-12 col-12">
                <div className="card dashboard-cards">
                  <div className="bg-white  py-3 card-header">
                    <h4 className="mb-0">Select Sheet Size</h4>
                  </div>
                  {tableLoader ? (
                    <Skeleton
                      count={20}
                      height="180px"
                      width="18.9%"
                      inline={true}
                      className="m-2"
                    />
                  ) : (
                    <div className="gift-scroll size-lists">
                      <div className="card-body on-off-cards">
                        <div className="row">
                          {sizeData.length
                            ? sizeData.map((el, index) => (
                                <div
                                  className="col-12 col-md-4 col-lg-2 text-center"
                                  key={index}
                                >
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`size${el.CardId}`}
                                      value={el.CardName}
                                      defaultChecked={
                                        cardData?.includes(el.CardName)
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      className="form-check-label form-check-label-with-size mt-2"
                                      htmlFor={`logo${el.CardId}`}
                                    >
                                      <span className="card-name-text">
                                        {el.CardName}
                                      </span>
                                      {/* <LogoLoader cardImg={el.CardImage} /> */}
                                    </label>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {!isLoading && (
              <div className="mt-4  col-md-12 col-12">
                <div className="card dashboard-cards">
                  <div className="bg-white  py-3 card-header">
                    <h4 className="mb-0">Select Sheet Color</h4>
                  </div>
                  {tableLoader ? (
                    <Skeleton
                      count={20}
                      height="180px"
                      width="18.9%"
                      inline={true}
                      className="m-2"
                    />
                  ) : (
                    <div className="gift-scroll color-lists">
                      <div className="card-body on-off-cards">
                        <div className="row">
                          {tableData.length
                            ? tableData.map((el, index) => (
                                <div
                                  className="col-12 col-md-4 col-lg-2 text-center"
                                  key={index}
                                >
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`color${el.CardId}`}
                                      value={el.CardName}
                                      // onChange={() => {
                                      //   setValues("color");
                                      // }}
                                      defaultChecked={
                                        cardData?.includes(el.CardName)
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      className="form-check-label form-check-label-with-color mt-2"
                                      htmlFor={`color${el.CardId}`}
                                    >
                                      <span className="card-name-text">
                                        {el.CardName}
                                      </span>
                                      <ImageLoader cardImg={el.CardImage} />
                                    </label>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="card-footer">
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary my-2"
                        type="submit"
                        onClick={handleButtonClick}
                      >
                        Update Voucher
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </main>
      <Footer />
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#fdc202"
          className="bar-loader"
        />
      )}
    </div>
  );
}
