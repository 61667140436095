import React from "react";
import img from "../../Images/spam.png";
import { Link } from "react-router-dom";
const EmailBox = () => {
  return (
    <div className="row">
      <div className="col-md-1 col-sm-2">
        <img src={img} />
      </div>
      <div className="col-md-11 mt-lg-2" style={{fontFamily: "Arial"}}>
        Please make sure to monitor your SPAM and JUNK folders as well.
      </div>
    </div>
  );
};
export default EmailBox;
