import React, { useEffect, useState } from "react";
import MainHeading from "../includes/MainHeading";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminListService from "../../../services/admin-list.service";
import ImageLoader from "../includes/ImageLoader";
import Skeleton from "react-loading-skeleton";
import LogoLoader from "../includes/LogoLoader";
import { useFormik } from "formik";
import { importDocument } from "../../../schema";
import { toast } from "react-toastify";
import $ from "jquery";
import ReactLoading from "react-loading";
import Test from "./Test";

export default function ImportCsv() {
  const [tableLoader, settableLoader] = useState(false);
  const [formSubmiting, setFormSubmiting] = useState(false);

  const [tableData, settableData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [duplicate, setDuplicate] = useState([]);
  const [errorData, setErrorData] = useState([]);

  const [sizeData, setSizeData] = useState([]);
  const [file, setFile] = useState("");
  const [giftError, setGiftError] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (adminAccessToken) {
      const logosLists = async () => {
        setLoader(true);
        try {
          const responce = await AdminListService.getAllLogos();

          const res = responce?.data?.response;

          const results = [];
          res.map((value) => {
            return results.push({
              CardName: value.name,
              CardImage: value.image,
              CardId: value.id,
            });
          });

          setSizeData([...results]);
          setFieldValue("size", "");

          setLoader(false);
        } catch (err) {
          console.log(err);
          setSizeData([]);

          setLoader(false);
        }
      };
      logosLists();
      const giftsLists = async () => {
        settableLoader(true);
        try {
          const responce = await AdminListService.getAllGifts();

          const res = responce?.data?.response;

          const results = [];
          res.map((value) => {
            return results.push({
              CardName: value.name,
              CardImage: value.image,
              CardId: value.id,
            });
          });

          settableData([...results]);
          setFieldValue("color", "");

          settableLoader(false);
        } catch (err) {
          console.log(err);
          settableData([]);

          settableLoader(false);
        }
      };
      giftsLists();
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const setValues = (field) => {
    var selectedValues = $(
      `.${field}-lists .on-off-cards input:checkbox:checked`
    )
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");
    console.log(selectedValues);
    setFieldValue(field, selectedValues);
  };

  const handleFileChange = async (e) => {
    setFile(e.target.files[0]);
    setFieldValue("file", e.target.value);
  };

  // FIXME color string concatenation
  const uploadSheet = async (values) => {
    console.log(`values=${JSON.stringify(values)}`);
    var selectedColors = $(".color-lists .on-off-cards input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");
    setFieldValue("color", selectedColors);

    var selectedSizes = $(".size-lists .on-off-cards input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");
    setFieldValue("size", selectedSizes);
    console.log(`color=${selectedColors.length},size=${selectedSizes.length},`);

    if (!selectedColors.length && !selectedSizes.length) {
      setGiftError(true);
      setSizeError(true);
    } else if (!selectedColors.length && selectedSizes.length) {
      setGiftError(true);
      setSizeError(false);
    } else if (selectedSizes.length && !selectedSizes.length) {
      setGiftError(false);
      setSizeError(true);
    } else {
      setFormSubmiting(true);
      setGiftError(false);
      setSizeError(false);

      const formData = new FormData();
      if (file !== "") {
        formData.append("file", file);
      }

      formData.append("adminId", parseInt(admin_id));

      formData.append("size", selectedSizes);
      formData.append("color", selectedColors);
      // formData.append("survey", values.survey);
      // formData.append("logo", values.logo);
      formData.append("email", values.email);

      try {
        const response = await AdminListService.uploadSheet(formData);

        toast.success(response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setDuplicate([]);
        setFormSubmiting(false);
        navigate("/admin/vouchers");
      } catch (err) {
        console.log(err);
        setFormSubmiting(false);
        if (
          err?.response?.data?.failure?.length === 0 &&
          err?.response?.data?.duplicate?.duplicate?.length > 0
        ) {
          setErrorData([]);
          setDuplicate(err?.response?.data?.duplicate?.duplicate);
        } else {
          if (Array.isArray(err?.response?.data?.failure)) {
            const errors = [];

            err?.response?.data?.failure.map((value) => {
              return errors.push({
                row: value.row,
                errors: value.errors[0].toString().replaceAll("_", " "),
              });
            });

            setErrorData([...errors]);
            setDuplicate([]);
          }
        }
        if (
          err?.response?.data?.response &&
          typeof err?.response?.data?.response === "string"
        ) {
          toast.error(err?.response?.data?.response, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err?.response?.data?.file?.length) {
          toast.error(err?.response?.data?.file[0], {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Something went wrong, try again", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };
  // FIXME color concatenation
  const { errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: { email: "no", file: "", color: "", size: "" },
    validationSchema: importDocument,
    onSubmit: (values) => {
      console.log(`submission=${JSON.stringify(values)}`);
      uploadSheet(values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          {/* <Test progress="100" /> */}
          {formSubmiting && <Test progress="100" />}
          <MainHeading heading="Import File" />
          <form className="row" onSubmit={handleSubmit} noValidate>
            <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
              <div
                className={`card dashboard-cards ${
                  errors.file && touched.file ? "error-card" : ""
                }`}
              >
                {" "}
                {errors.file && touched.file ? (
                  <span className="text-danger">{errors.file}</span>
                ) : null}
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <h4 className="mb-0 col-lg-8">You Can Import XLSX File</h4>
                    <div className="col-lg-4 text-end">
                      <a
                        href="/assets/csv/PYS_upload_sheet_sample.xlsx"
                        download={true}
                        className="btn btn-primary"
                      >
                        DOWNLOAD SAMPLE SHEET
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {errorData.length ? (
                    <div className="row">
                      {errorData.map((res, index) => {
                        return (
                          <div className="col-lg-6">
                            <div className="alert alert-danger " key={index}>
                              <b>Note!</b> Problem in row number {res.row} ,
                              <b> Error! </b>
                              {res.errors}
                            </div>{" "}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="SendeVoucher"
                        onChange={(e) => {
                          setFieldValue(
                            "email",
                            e.target.checked ? "yes" : "no"
                          );
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="SendeVoucher"
                      >
                        Send eVoucher
                      </label>
                    </div>
                  </div>
                  <div className=" file-area">
                    <input
                      type="file"
                      name="images"
                      id="images"
                      required="required"
                      accept=".xls, .xlsx"
                      onChange={handleFileChange}
                    />
                    <div className="file-dummy">
                      <div className="success">
                        Great, your file is selected. Keep on.
                      </div>
                      <div className="default">Import XLSX File</div>
                    </div>
                  </div>
                  {duplicate.length ? (
                    <div className="table-responsive mt-3">
                      <table className="text-nowrap  table caption-top">
                        <caption>
                          <strong className="font-17">
                            These certificates are not saved due to duplication.
                          </strong>
                        </caption>
                        <thead className="table-light">
                          <tr>
                            <th scope="col">Sr#</th>

                            <th scope="col">Certificate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {duplicate.map((el, index) => (
                            <tr key={index}>
                              <td className="align-middle">{index + 1}</td>
                              <td className="align-middle">
                                <span className="text-control">{el}</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : null}

                  {/* <div
                    className="alert alert-danger d-flex align-items-center mt-4 mb-0 font-17"
                    role="alert"
                  >
                    <div>
                      <strong>Note:</strong> If you don't want to display any
                      logo on user form and thank you page, please select 'No
                      Logo' image. If you don't want to display survey on user
                      form please select 'No Survey'.
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
              <div
                className={`card dashboard-cards ${
                  errors.survey && touched.survey ? "error-card" : ""
                }`}
              >
                {errors.survey && touched.survey ? (
                  <span className="text-danger">{errors.survey}</span>
                ) : null}
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Select Survey</h4>
                </div>
                <div className="card-body ">
                  <div className="row">
                    <div className="col-12 col-md-4 col-lg-2">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="0"
                          value="0"
                          name="Survey"
                          onChange={(e) => {
                            setFieldValue(
                              "survey",
                              e.target.checked ? "no" : ""
                            );
                          }}
                        />
                        <label className="form-check-label" htmlFor="0">
                          No Survey
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="1"
                          value="1"
                          name="Survey"
                          onChange={(e) => {
                            setFieldValue(
                              "survey",
                              e.target.checked ? "yes" : ""
                            );
                          }}
                        />
                        <label className="form-check-label" htmlFor="1">
                          Show Survey
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
              <div
                className={`card dashboard-cards ${
                  errors.size && touched.size ? "error-card" : ""
                }`}
              >
                {errors.size && touched.size ? (
                  <span className="text-danger">{errors.size}</span>
                ) : null}
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Select Sheet Size</h4>
                </div>
                {Loader ? (
                  <Skeleton
                    count={6}
                    height="180px"
                    width="15.6%"
                    inline={true}
                    className="m-2"
                  />
                ) : (
                  <div className="gift-scroll size-lists">
                    <div className="card-body on-off-cards">
                      <div className="row">
                        {sizeData.length
                          ? sizeData.map((el) => (
                              <div
                                className="col-12 col-md-4 col-lg-2 text-center"
                                key={el.CardId}
                              >
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`size${el.CardId}`}
                                    value={el.CardName}
                                    // onChange={(e) => {
                                    //   setFieldValue(
                                    //     "size",
                                    //     e.target.checked ? el.CardName : ""
                                    //   );
                                    // }}
                                    onChange={() => {
                                      setValues("size");
                                    }}
                                    name="size"
                                  />
                                  <label
                                    className="form-check-label form-check-label-with-size mt-2"
                                    htmlFor={`size${el.CardId}`}
                                  >
                                    <span className="card-name-text">
                                      {el.CardName}
                                    </span>
                                    {/* <LogoLoader cardImg={el.CardImage} /> */}
                                  </label>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4  col-md-12 col-12">
              <div
                className={`card dashboard-cards ${
                  errors.color && touched.color ? "error-card" : ""
                }`}
              >
                {errors.color && touched.color ? (
                  <span className="text-danger">{errors.color}</span>
                ) : null}

                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Select Sheet Color</h4>
                </div>
                {tableLoader ? (
                  <Skeleton
                    count={20}
                    height="180px"
                    width="18.9%"
                    inline={true}
                    className="m-2"
                  />
                ) : (
                  <div className="gift-scroll color-lists">
                    <div className="card-body on-off-cards">
                      <div className="row">
                        {tableData.length
                          ? tableData.map((el, index) => (
                              <div
                                className="col-12 col-md-4 col-lg-2 text-center"
                                key={el.CardId}
                              >
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`color${el.CardId}`}
                                    value={el.CardName}
                                    onChange={() => {
                                      setValues("color");
                                    }}
                                    name="color"
                                  />
                                  <label
                                    className="form-check-label form-check-label-with-text mt-2"
                                    htmlFor={`color${el.CardId}`}
                                  >
                                    <span className="card-name-text">
                                      {el.CardName}
                                    </span>
                                    <ImageLoader cardImg={el.CardImage} />
                                  </label>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                )}

                <div className="card-footer">
                  <div className="col-12 text-center">
                    <button className="btn btn-primary my-2 " type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
      <Footer />
      {/* {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#fdc202"
          className="bar-loader"
        />
      )} */}
      {/* <Test progress="75" /> */}
      {/* {formSubmiting && <Test progress="75" />} */}
    </div>
  );
}
