import React from "react";
import ToTop from "./ToTop";

export default function Footer() {
  return (
    <>
      <footer className="site-footer-main">
      Copyright &copy; {new Date().getFullYear()}{" "} 
      <span className="fotr-title">{process.env.REACT_APP_API_SITE_TITLE}</span>. All rights reserved.
        {/* Copyright &copy; {new Date().getFullYear()}{" "}
        <span className="fotr-title">{process.env.REACT_APP_API_SITE_TITLE}</span>. All Rights Reserved. */}
      </footer>
      <ToTop />
    </>
  );
}
